/* eslint-disable security/detect-object-injection */
import { ServerContext, QueryParams } from '../../../core/interfaces'
import { getDeviceType } from '../../../core/tools/infos'

export default function getSanitizedQuery(context: ServerContext): QueryParams {
  const device = getDeviceType(context.req.headers['user-agent'])
  const query: QueryParams = { device }

  Object.entries(context.query).forEach(([key, value]) => {
    if (key !== 'country' && key !== 'language') {
      query[key === 'id' ? 'entityId' : key] = Array.isArray(value)
        ? value.join(',')
        : value
    }
  })

  return query
}
