import { GetServerSidePropsContext } from 'next'
import stringifyQuery from './stringifyQuery';
import setCookie from './setCookie';

const usingDeeplink = (
  context: GetServerSidePropsContext
): { applicationId: string; accountId?: string; storeId?: string } => {
  const { req, res, query } = context

  const { poc, storeId, store } = stringifyQuery(query)

  if (poc) setCookie(res, 'REDUXPOC', poc)
  if (storeId) {
    setCookie(res, 'STOREKEY', '')
    setCookie(res, 'STOREID', storeId)
  }
  // Deprecated
  if (store) setCookie(res, 'STOREKEY', 'F')

  const {
    APPLICATION = 'customer',
    REDUXPOC = null,
    STOREID = null,
  } = req.cookies

  return {
    applicationId: APPLICATION.toLowerCase(),
    storeId: storeId || STOREID,
    accountId: poc || REDUXPOC,
  }
}

export default usingDeeplink
