/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/camelcase */
import { IPage } from '@bees-web/core/types'
import React, { FC } from 'react'
import { GetServerSideProps } from 'next'
import { getDomainLocale } from '../utils/VL'
import { TemplateProvider } from '../core/templateProvider'
import fetcher from './fetcher'
import { ServerPageProps, UserInfos } from '../core/interfaces'
import { ScriptProps } from 'next/script'
import {
  getSanitizedQuery,
  usingDeeplink,
  usingLocale,
  usingTrackingData,
} from '../core/tools/serverSide'
import ServerPage from '../core/components/ServerPage'
import {
  notFound,
  serviceUnavailable,
  notAuthorized,
} from './errorPageRedirects'
import { BareFetcher } from 'swr'

export interface CreatePageProps {
  namespace: string
  locolib: string
  trackName?: string
  pageKey?: string
  scripts?: ScriptProps[]
  dependencies?: string[]
  options?: {
    cachePage?: boolean
    processDeferredOnServer?: boolean
    noAuthentication?: boolean
  }
}

export type SSRNextPage = {
  Page: FC<ServerPageProps>
  getServerSideProps: GetServerSideProps<ServerPageProps>
}

export const isSingleConfig = (obj: any): obj is CreatePageProps =>
  typeof obj === 'object' &&
  obj !== null &&
  typeof obj.namespace === 'string' &&
  typeof obj.locolib === 'string'

export const isMapOfConfig = (
  obj: any
): obj is Record<string, CreatePageProps> =>
  typeof obj === 'object' &&
  obj !== null &&
  !Array.isArray(obj) &&
  Object.values(obj).every((value) => isSingleConfig(value))

export function createPage(
  props: CreatePageProps | Record<string, CreatePageProps>,
  configOverride?: (pageProps: ServerPageProps) => ServerPageProps
): SSRNextPage {
  const Page: FC<ServerPageProps> = (props) => <ServerPage {...props} />

  const getServerSideProps: GetServerSideProps<ServerPageProps> = async (
    context
  ) => {
    const { isSupported, locale } = getDomainLocale(context)

    if (isSupported) {
      const { country, language } = usingLocale(context, locale)
      const { ajsAnonymousId, ajsUserId } = usingTrackingData(context)
      const { applicationId, accountId, storeId } = usingDeeplink(context)

      const creationProps = isSingleConfig(props)
        ? props
        : isMapOfConfig(props)
        ? props[applicationId]
        : null

      if (creationProps) {
        const {
          namespace,
          locolib: name,
          trackName = null,
          pageKey,
          scripts = [],
          dependencies = null,
          options = {},
        } = creationProps

        const customFetch = fetcher<IPage | UserInfos>(context)
        const query = getSanitizedQuery(context)
        // TODO: make default useCache = true
        const { cachePage = false, noAuthentication = false } = options

        // TODO: remove this in the future
        if (
          !noAuthentication &&
          !context.req.headers.cookie?.includes('connect.sid')
        ) {
          return notAuthorized(country, language, context?.req?.url)
        }

        const coreQs = cachePage
          ? { locale }
          : {
              ...query,
              locale,
              ...(accountId ? { selectedPocAccount: accountId } : {}),
              ...(storeId ? { storeId } : {}),
            }

        const templatePromise = new TemplateProvider(
          customFetch as BareFetcher<IPage>
        ).getTemplate(name, coreQs, cachePage)
        const userInfosPromise = !noAuthentication
          ? customFetch('api/user-infos')
          : Promise.resolve({})

        const [templateSettled, userInfosSettled] = await Promise.allSettled([
          templatePromise,
          userInfosPromise,
        ])

        let template: IPage
        if (templateSettled.status === 'rejected') {
          return notAuthorized(country, language, context?.req?.url)
        } else {
          template = templateSettled.value
        }

        if (userInfosSettled.status === 'rejected')
          console.error('Failed to fetch user infos', userInfosSettled)

        if (template) {
          const pageProps: ServerPageProps = {
            namespace,
            dependencies,
            name,
            applicationId,
            accountId,
            storeId,
            trackName,
            pageKey: (pageKey ?? name).toUpperCase(),
            locale,
            pathname: context.resolvedUrl,
            scripts,
            query,
            ajsAnonymousId,
            ajsUserId,
            template,
            userInfos:
              userInfosSettled.status === 'fulfilled'
                ? userInfosSettled.value
                : {},
          }

          console.info('Using Static Routing System:', pageProps)

          return configOverride
            ? { props: configOverride(pageProps) }
            : { props: pageProps }
        } else {
          return serviceUnavailable
        }
      }
    }

    return notFound
  }

  return { Page, getServerSideProps }
}
