import { ParsedUrlQuery } from 'querystring'

const stringifyQuery = (query: ParsedUrlQuery): Record<string, string> =>
  Object.keys(query).reduce((prev, curr) => {
    const value = query[curr]
    return {
      ...prev,
      [curr]: typeof value === 'string' ? value : value[0],
    }
  }, {})

export default stringifyQuery
