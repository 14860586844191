import { GetServerSidePropsContext } from 'next'
import setCookie from './setCookie'

export default function usingLocale(
  context: GetServerSidePropsContext,
  locale: string
): { country: string; language: string } {
  const [language, country] = locale.split('_')
  setCookie(context.res, 'country', country, false)
  setCookie(context.res, 'language', language, false)

  return { country, language }
}
