import { ServerResponse } from 'http'

const setCookie = (res: ServerResponse, key: string, value: string, strict: boolean = true) => {
  const isStrict = strict ? 'Strict' : 'None'
  const currentCookies = res.getHeader('Set-Cookie') || []
  const newCookie = `${key}=${value}; Path=/; SameSite=${isStrict}; Secure` //`${key}=${value}; Path=/; HttpOnly; SameSite=Strict; Secure`

  const updatedCookies = Array.isArray(currentCookies)
    ? [...currentCookies, newCookie]
    : [currentCookies, newCookie]

  res.setHeader('Set-Cookie', updatedCookies as readonly string[])
}

export default setCookie
