import { uuidv4 } from '@bees-web/nfa-interactive-global'
import { GetServerSidePropsContext } from 'next'
import cookies from 'next-cookies'

/* eslint-disable @typescript-eslint/camelcase */
export default function usingTrackingData(context: GetServerSidePropsContext): {
  ajsAnonymousId: string
  ajsUserId: string | null
} {
  const { ajs_user_id = null, ajs_anonymous_id = null } = cookies(context)
  const ajsUserId = ajs_user_id?.replace(/[^a-z0-9-_]/gi, '') || null
  const ajsAnonymousId = ajs_anonymous_id || uuidv4()
  if (!ajs_anonymous_id)
    context.res.setHeader('ajs_anonymous_id', ajsAnonymousId)
  return { ajsAnonymousId, ajsUserId }
}
