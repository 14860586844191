import { createPage } from '../core/createPage'

const nextPage = createPage({
  locolib: 'global_error_404',
  namespace: 'home',
  dependencies: [],
  options: {
    cachePage: true,
    noAuthentication: true,
  },
})

export default nextPage.Page

export const getServerSideProps = nextPage.getServerSideProps
